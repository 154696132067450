import React from "react";
import { Link } from "react-router-dom";
import { formatNumber } from "../../helpers/formatNumber";
import { usePrices } from "../../hooks/usePrices";
import "./CardItem.css";

const CardItem = ({ modelo }) => {
  const precioModelo = {
    entrega: usePrices([modelo])[0][0],
    contado: usePrices([modelo])[0][2],
    cuota: usePrices([modelo])[0][1],
  };

  return (
    <Link className="container-carditem__link" to={`/modelo/${modelo?.id}`}>
      <article className="container-carditem">
        {modelo?.placeholder?.length === 1 ? (
          <p
            className={
              modelo?.tipologia == "millon"
                ? "container-carditem__titulo millon_title"
                : "container-carditem__titulo"
            }
          >
            <strong> {modelo?.placeholder[0]}</strong>
          </p>
        ) : (
          <p className="container-carditem__titulo">
            <small>{modelo?.placeholder[0]} </small>&nbsp;|&nbsp;
            <strong> {modelo?.placeholder[1]}</strong>
          </p>
        )}
        <div className="container-carditem__card">
          <figure className="container-carditem__card__contenedor-imagen">
            <img
              className="container-carditem__card__contenedor-imagen__imagen"
              src={
                modelo?.tipologia == "millon"
                  ? `/modelos/${modelo?.tipologia}_${modelo?.id}/Modelo_${modelo?.id}_card.webp`
                  : `/modelos/${modelo?.tipologia}_${modelo?.id}/Modelo_${modelo?.id}_card.webp`
              }
              alt="modelo de un millón"
            />
          </figure>
          <div className="container-carditem__card__detalles">
            <div className="container-carditem__card__detalles__ambientes">
              <div className="container-carditem__card__detalles__ambientes__item">
                <span>
                  {modelo?.superficies?.superficieCubierta +
                    modelo?.superficies?.superficieSemicubierta}
                  m²
                </span>
                <img src="/icons/metros.svg" alt="Metros cuadrados" />
              </div>
              <div className="container-carditem__card__detalles__ambientes__item">
                <span>{modelo?.ambientes?.cantidadDeDormitorios} dorm</span>
                <img src="/icons/dormitorio-card.svg" alt="Dormitorios" />
              </div>
              <div className="container-carditem__card__detalles__ambientes__item">
                <span>
                  {modelo?.ambientes?.cantidadDeBanos === 1
                    ? `${modelo?.ambientes?.cantidadDeBanos} baño`
                    : `${modelo?.ambientes?.cantidadDeBanos} baños`}
                </span>
                <img src="/icons/bano-card.svg" alt="Baños" />
              </div>
            </div>

            <div className="precio_millon">
              <p style={{ fontSize: "27px", fontWeight: 900 }}>{`US$${formatNumber(precioModelo?.contado)}`} <span style={{ fontSize: "14px", fontWeight: 800 }}>+ IVA</span></p>
              <div className="precio_millon_label">
                <div className="precio_millon_paralelogram">
                  <p>Precio de contado</p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <buuton className="container-carditem__calltoaction" href="#">
          <div className="container-carditem__calltoaction__fecha">
            <img src="/icons/arrow.svg" alt="flecha" />
          </div>
          <span>Construí Ya</span>
        </buuton>
      </article>
    </Link>
  );
};

export default CardItem;
